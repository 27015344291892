import { BCLocation } from "./util/BCLocation";
import { SERVER_URL } from "./const";


export const fetchData = async (l: BCLocation): Promise<any[]> => {
    try {

        let isPlainText = false;

        if (!l.password) {
            isPlainText = true;
            l.password = prompt("Please enter the password");
        }

        const response = await fetch(`${SERVER_URL}/material/media/${l.classId}`, {
            method: 'POST',
            body: JSON.stringify({
                passcode: l.password,
                type: 'passwordhash',
            }),
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
        });

        const res = await response.json();
        console.log(res);
        if (res.status === 404) {
            renderNotFound();
        } else {
            return res;
        }
    } catch (e) {
        renderNotFound();
        throw e;
    }
}

const renderNotFound = async () => {
    alert("Not found");
}

export const mockData = [
    {
        "name": "Material 2",
        "type": "pdf",
        "resource_url": "https://cdn.rawgit.com/mozilla/pdf.js/master/web/compressed.tracemonkey-pldi-09.pdf"
    },
    {
        "name": "1",
        "type": "video",
        "resource_url": "https://storage.googleapis.com/brainchild-prod-bucket/swEzR3XbZs04p7XMrarkyh7JmXtqYKfy.WhatsApp%20Video%202020-11-01%20at%206.54.24%20PM.mp4"
    },
    {
        "name": "Material 3",
        "type": "youtube",
        "resource_url": "8wcrQ-IK71g"
    }
    , {
        "name": "Material 1",
        "description": "some text",
        "type": "url",
        "resource_url": "http://google.com"
    },
    {
        "name": "Material 2",
        "type": "image",
        "resource_url": "https://sites.google.com/site/dekchysite95/_/rsrc/1480944463347/extra-credit/google.png"
    },
]
