import { render as renderPdf } from "./pdf";
import { video } from "./video";
import watermark from 'watermarkjs';
import { fixSafariScrolling } from "../util/fixSafariScrolling";

export const renderMaterial = async (content: HTMLElement, material: any, isRefresh: boolean) => {
    if (!isRefresh) {
        await reset();
    }
    switch (material.type) {
        case "image":
            return renderImage(content, material);
        case "url":
            return renderUrl(content, material);
        case "youtube":
            return renderYoutube(content, material);
        case "pdf":
            return renderPdf(content, material, isRefresh);
        case "video":
            return video.render(content, material, isRefresh);
    }
}

const reset = async () => {
    await video.reset();
}

const renderImage = async (content: HTMLElement, material: any) => {
    content.innerHTML = "";

    const options = {
        init(img) {
            img.crossOrigin = 'anonymous'
        }
    };

    await watermark([material.resource_url, '../public/image/watermark.png'], options)
        .image(watermark.image.lowerRight(0.5))
        .then((img: HTMLElement) => {
            img.onclick = () => {
                if (img.classList.contains('zoom-in')) {
                    content.classList.remove('zoom-in');
                    img.classList.remove('zoom-in');
                } else {
                    img.classList.add('zoom-in');
                    content.classList.add('zoom-in');
                }
            }

            fixSafariScrolling(content);
            return content.appendChild(img);
        });
}

const renderYoutube = async (content: Element, material: any) => {
    content.innerHTML = "";

    const container = document.createElement("div");
    content.appendChild(container);
    {
        container.classList.add("video-container");

        const iframe = document.createElement("iframe");
        iframe.classList.add("video");
        iframe.src = `https://www.youtube.com/embed/${material.resource_url}`;
        iframe.setAttribute("frameborder", "0");
        iframe.setAttribute("allow", "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture");
        iframe.setAttribute("allowfullscreen", "");

        container.appendChild(iframe);
    }
}

const renderUrl = async (content: Element, material: any) => {
    content.innerHTML = "";

    const container = document.createElement("div");
    {
        container.classList.add("url-container");

        const metaContainer = document.createElement("div");
        container.appendChild(metaContainer);
        {
            metaContainer.classList.add("meta-container");

            const title = document.createElement("div");
            title.classList.add("title");
            title.innerText = material.name;
            metaContainer.appendChild(title);

            const description = document.createElement("div");
            description.classList.add("description");
            description.innerHTML = material.description;

            metaContainer.appendChild(description);
        }


        const textContainer = document.createElement("div");
        container.appendChild(textContainer);
        {
            textContainer.classList.add("text-container");

            const a = document.createElement("a");
            a.href = material.resource_url;
            a.target = "_blank";
            a.innerHTML = `Go!`;

            textContainer.appendChild(a);
        }

    }

    content.appendChild(container);
}