import * as pdfjs from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

let pdfCache = null;

export const render = async (content: Element, material: any, isRefresh: boolean) => {
    content.innerHTML = "";

    const container = document.createElement("div");
    {
        container.classList.add("pdf-container");

        const placeholder = document.createElement('div');
        placeholder.classList.add("placeholder");
        placeholder.classList.add("top");
        // container.appendChild(placeholder);

        if (isRefresh && pdfCache) {
            await createPdfCanvas(container, pdfCache);
        } else {
            await new Promise((resolve) => {
                pdfjs.getDocument(material.resource_url).promise.then(async (pdf: pdfjs.PDFDocumentProxy) => {
                    pdfCache = pdf;
                    await createPdfCanvas(container, pdf);
                    resolve();
                });
            });
        }

        const placeholder2 = document.createElement('div');
        placeholder2.classList.add("placeholder");
        placeholder2.classList.add("bottom");
        // container.appendChild(placeholder2);
    }

    const watermarkContainer = document.createElement('div');
    watermarkContainer.classList.add("brainchild-watermark");

    container.appendChild(watermarkContainer)
    content.appendChild(container);
}

const createPdfCanvas = async (container: Element, pdf: pdfjs.PDFDocumentProxy) => {
    return new Promise((resolve) => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        for (var pageNum = 1; pageNum <= pdf.numPages; ++pageNum) {
            const div = document.createElement('div');
            container.appendChild(div);
            {
                pdf.getPage(pageNum).then((page) => {
                    const defaultViewport = page.getViewport({
                        scale: 1,
                    });

                    const viewport = page.getViewport({
                        scale: ((window.innerWidth) / defaultViewport.width) * 0.90,
                        offsetX: 0,
                        offsetY: 0,
                    });

                    var canvas = document.createElement('canvas');
                    canvas.style.overflow = 'scroll';
                    var context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    var renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };
                    div.appendChild(canvas)
                    page.render(renderContext);
                });
            }
        }
        resolve();
    });
}

// const addWatermark = (container: Element) => {
//     const watermarkContainer = document.createElement('div');
//     watermarkContainer.classList.add("brainchild-watermark");

//     const watermark = document.createElement('img');
//     watermark.src = "../public/image/watermark.png";

//     watermarkContainer.appendChild(watermark);

//     container.appendChild(watermarkContainer)
// }