import 'regenerator-runtime/runtime';
import { fetchData, mockData } from "./data";
import { renderMaterial } from "./render/renderMaterial";
import { BCLocation } from './util/BCLocation';
const screenfull = require("screenfull");

type El = HTMLElement & { value: any };

let current = 0;
let rendered = -1;
let max = 10;
let materials = [];

// const body = document.getElementsByTagName("body")[0];
// const container = document.getElementsByClassName("container")[0];
const fullscreenBtn = document.getElementById("fullscreen");
const nextBtn = document.getElementById("next");
const prevBtn = document.getElementById("prev");
const currentInput = document.getElementById("current") as El;
const title = document.getElementById("title") as El;
title.style.display = 'none';
const content = document.getElementsByClassName("content")[0] as HTMLElement;

const load = async () => {
    try {
        const info = new BCLocation(window.location);
        
        materials = await fetchData(new BCLocation(window.location));
        // materials = mockData;
        max = materials.length - 1;
        await render();
    } catch (e) {

    }
}

const renderButtons = async () => {
    nextBtn.classList.remove("disabled");
    nextBtn.removeAttribute("disabled");
    prevBtn.classList.remove("disabled");
    prevBtn.removeAttribute("disabled");

    if (current === max) {
        nextBtn.classList.add("disabled");
        nextBtn.setAttribute("disabled", "");
    } else if (current === 0) {
        prevBtn.classList.add("disabled");
        prevBtn.setAttribute("disabled", "");
    }
}

const render = async () => {
    currentInput.value = (current + 1).toString();

    await renderButtons();

    const material = materials[current];
    await renderMaterial(content, material, rendered === current);

    title.innerText = material.name;

    rendered = current;
};

const next = async () => {
    current = current + 1 < max ? current + 1 : max;
    await render();
};

const prev = async () => {
    current = current - 1 > 0 ? current - 1 : 0;
    await render();
};

const checkCurrent = async () => {
    if (isNaN(parseInt(currentInput.value))) {
        currentInput.value = (current + 1).toString();
    } else {
        const input = parseInt(currentInput.value);
        if (current === input - 1) {
            currentInput.value = (current + 1).toString();
            return;
        }

        if (input - 1 < 0) {
            current = 0;
        } else if (input - 1 >= max) {
            current = max;
        } else if (current !== input) {
            current = input;
        }
        await render();
    }
}

const fullscreen = () => {
    if (screenfull.isEnabled) {
        screenfull.toggle();
    }
};

nextBtn.onclick = next;
prevBtn.onclick = prev;
currentInput.onchange = checkCurrent;
fullscreenBtn.onclick = fullscreen;

document.addEventListener('contextmenu', event => event.preventDefault());
document.ontouchmove = function (event) {
    event.preventDefault();
}

window.onresize = render;

load();
