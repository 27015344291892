import { NO_CLASSID_ERROR, NO_PASSWORD_ERROR } from "./error";

export class BCLocation {

    public classId: number;
    public password: string = null;

    public constructor(location: Location) {
        const params = deparam(location.search);

        if(params.c){
            this.classId = parseInt(params.c);
        } else {
            throw NO_CLASSID_ERROR;
        }

        if(params.p) {
            this.password = params.p;
        }
    }
}




const deparam = (function (d, x, params, p, i, j) {
    return function (qs) {
        // start bucket; can't cheat by setting it in scope declaration or it overwrites
        params = {};
        // remove preceding non-querystring, correct spaces, and split
        qs = qs.substring(qs.indexOf('?') + 1).replace(x, ' ').split('&');
        // march and parse
        for (i = qs.length; i > 0;) {
            p = qs[--i];
            // allow equals in value
            j = p.indexOf('=');
            // what if no val?
            if (j === -1) params[d(p)] = undefined;
            else params[d(p.substring(0, j))] = d(p.substring(j + 1));
        }

        return params;
    };//--  fn  deparam
})(decodeURIComponent, /\+/g);
